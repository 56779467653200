.birthday-card-container {
  position: relative;
  width: 1200px;
  height: 1500px;
  color: black;

  .birthday-frame {
    object-fit: cover;
    z-index: 0;
    position: absolute;
  }

  .birthday-card-info-container {
    font-family: 'BMKing001-Regular';

    .agent-profile {
      z-index: -1;
      position: absolute;
      -o-object-fit: cover;
      object-fit: cover;
      // opacity: 80%;
      &.v1 {
        bottom: 21%;
        left: 38%;
        width: 749px;
        height: 786px;
      }
      &.v2 {
        bottom: 18%;
        left: 43%;
        width: 576px;
        height: 778px;
      }
    }

    .date-month {
      position: absolute;
      font-size: 50px;
      font-weight: 500;
      right: 10%;

      &.birthday-card-one {
        top: 12%;
      }

      &.birthday-card-two {
        top: 16%;
      }
    }

    .agent-info {
      position: absolute;
      width: 100%;
      text-align: center;
      &.agent-name {
        font-size: 62px;
        font-weight: bold;
        top: 83%;
        text-align: center;
        letter-spacing: -2px;
        &.v1 {
          left: 20%;
        }
        &.v2 {
          left: 18%;
        }
      }
      &.myan_font {
        font-size: 62px;
      }
      &.more-twenty {
        font-size: 54px;
        // left: 2%;
      }

      &.agent-code {
        font-size: 54px;
        font-weight: bold;
        left: 18.3%;
        top: 89%;
        text-align: center;
        &.v1 {
          left: 18.3%;
        }

        &.v2 {
          left: 17.4%;
        }
      }
    }
    .white {
      color: #ffffff;
    }
    .black {
      color: #000000;
    }
    .black-white {
      text-shadow: rgb(255, 255, 255) 3px 0px 0px, rgb(255, 255, 255) 2.83487px 0.981584px 0px,
        rgb(255, 255, 255) 2.35766px 1.85511px 0px, rgb(255, 255, 255) 1.62091px 2.52441px 0px,
        rgb(255, 255, 255) 0.705713px 2.91581px 0px, rgb(255, 255, 255) -0.287171px 2.98622px 0px,
        rgb(255, 255, 255) -1.24844px 2.72789px 0px, rgb(255, 255, 255) -2.07227px 2.16926px 0px,
        rgb(255, 255, 255) -2.66798px 1.37182px 0px, rgb(255, 255, 255) -2.96998px 0.42336px 0px,
        rgb(255, 255, 255) -2.94502px -0.571704px 0px, rgb(255, 255, 255) -2.59586px -1.50383px 0px,
        rgb(255, 255, 255) -1.96093px -2.27041px 0px, rgb(255, 255, 255) -1.11013px -2.78704px 0px,
        rgb(255, 255, 255) -0.137119px -2.99686px 0px, rgb(255, 255, 255) 0.850987px -2.87677px 0px,
        rgb(255, 255, 255) 1.74541px -2.43999px 0px, rgb(255, 255, 255) 2.44769px -1.73459px 0px,
        rgb(255, 255, 255) 2.88051px -0.838246px 0px;
    }
    .white-black {
      color: #ffffff;
      text-shadow: rgb(0, 0, 0) 3px 0px 0px, rgb(0, 0, 0) 2.83487px 0.981584px 0px,
        rgb(0, 0, 0) 2.35766px 1.85511px 0px, rgb(0, 0, 0) 1.62091px 2.52441px 0px,
        rgb(0, 0, 0) 0.705713px 2.91581px 0px, rgb(0, 0, 0) -0.287171px 2.98622px 0px,
        rgb(0, 0, 0) -1.24844px 2.72789px 0px, rgb(0, 0, 0) -2.07227px 2.16926px 0px,
        rgb(0, 0, 0) -2.66798px 1.37182px 0px, rgb(0, 0, 0) -2.96998px 0.42336px 0px,
        rgb(0, 0, 0) -2.94502px -0.571704px 0px, rgb(0, 0, 0) -2.59586px -1.50383px 0px,
        rgb(0, 0, 0) -1.96093px -2.27041px 0px, rgb(0, 0, 0) -1.11013px -2.78704px 0px,
        rgb(0, 0, 0) -0.137119px -2.99686px 0px, rgb(0, 0, 0) 0.850987px -2.87677px 0px,
        rgb(0, 0, 0) 1.74541px -2.43999px 0px, rgb(0, 0, 0) 2.44769px -1.73459px 0px,
        rgb(0, 0, 0) 2.88051px -0.838246px 0px;
    }
    .green {
      color: #525252;
    }
  }
}
