.bonus_frame_container {
  display: flex;
  justify-content: flex-end;
}

.bonus_frame_container__button,
.bonus_frame_container__button:hover,
.bonus_frame_container__button:focus,
.bonus_frame_container__button:active {
  font-family: 'Public Sans', sans-serif !important;
  font-weight: 700 !important;
  box-shadow: rgb(0 171 85 / 24%) 0px 8px 16px 0px !important;
  color: rgb(255, 255, 255) !important;
  background-color: rgb(0, 171, 85) !important;
  outline: 0px !important;
  border: 0px !important;
  margin: 0px !important;
  padding: 7px 16px !important;
}

.bonus_frame_dropdown_container {
  background-color: rgb(0, 171, 85) !important;
  width: 100% !important;
}

.bonus_frame_dropdown_menu {
  font-family: 'Public Sans', sans-serif !important;
  color: white !important;
  font-weight: 700 !important;
}

.bonus_frame_dropdown_menu:hover {
  background-color: rgb(0, 100, 52) !important;
  color: white !important;
}
